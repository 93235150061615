import {
    Box,
    Stack,
    Button,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

export default function Feature({ buttonText, functionToCall }) {
    const [isLoading, setIsLoading] = useState(false)
    const [dataToShow, setDataToShow] = useState([])
    const [processState, setProcessState] = useState()
    const [errorMsg, setErrorMsg] = useState();
    return (
        <Stack sx={{ padding: 2, alignItems: "center" }} direction="column">
            <Stack direction="column" sx={{ maxWidth: 500, width: "100%" }}>
                <Box sx={{ padding: 2 }}>{
                    isLoading ?
                        <Button variant="contained" sx={{ color: "ffffff" }}><CircularProgress color="inherit" />{processState}</Button> :
                        <Button variant="contained" onClick={() => functionToCall({ setIsLoading, setProcessState, setDataToShow, setErrorMsg })}>{buttonText}</Button>
                }
                </Box>
                {errorMsg?.type === 'other' && <div style={{ color: "#d32f2f", marginBottom: 10 }}>Error: {errorMsg.value}</div>}
            </Stack>
            {dataToShow.map((el, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        {el.action}
                    </AccordionSummary>
                    <AccordionDetails>
                        <textarea style={{ minWidth: 500, alignItems: "center", width: "100%", height: 400 }} defaultValue={el.data} />
                    </AccordionDetails>
                </Accordion>
            ))
            }
            <Box sx={{ mt: 4 }}>
                {processState === "Success!" && <Alert severity="success">Passkey {buttonText} is successful!</Alert>}
                {processState === "Error" && <Alert severity="error">Error!</Alert>}
            </Box>
        </Stack>
    )
}