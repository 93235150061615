import {
    TextField
  } from '@mui/material';

export default function Input ({onChange, type, label, helperText, errorMsg, ...props}) {
    const error = type === errorMsg?.type && errorMsg.value
    return (
    <TextField 
    label={label}
    variant="standard"
    onChange={onChange} defaultValue={!sessionStorage.getItem(type) ? "" : sessionStorage.getItem(type)} {...props}
    error={error && true}
    helperText={error || helperText}
    />
    )
}